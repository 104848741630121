import React, { useContext, useState } from "react";
import { FirebaseContext } from "../components/Firebase";
import Header from "../components/Header";
import Layout from "../layout/main";
import Usps from "../containers/Home/Usps";
import { createCheckoutSession } from "../components/Stripe/createCheckoutSession";
import ForgotPassword from "../containers/Authentication/ForgotPassword";
import { navigate } from "gatsby-link";

const ForgotPasswordPage = () => {
  const { user, firebase, loading } = useContext(FirebaseContext);

  user && (window.location.href = "/dashboard");
  return (
    // <Layout pageTitle="Login">
    // </Layout>
    <>
      <ForgotPassword />
      <Usps />
    </>
  );
};

export default ForgotPasswordPage;
