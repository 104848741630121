import { navigate } from "gatsby-link";
import React, { useContext, useState } from "react";
import { FirebaseContext } from "../../components/Firebase";
import styled from "styled-components";
import LoadingWall from "../../components/LoadingWall";
import errorDictionary from "../../helpers/errorDictionary";

const RegisterRedirectContainer = styled.div`
  text-align: center;
  cursor: pointer;

  &:hover {
    color: #302363;
  }
`;
const ErrorMessageText = styled.div`
  color: red;
  text-align: center;
`;

function ForgotPassword() {
  const { firebase } = useContext(FirebaseContext);
  const [formValues, setFormValues] = useState({
    email: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    firebase.auth
      .sendPasswordResetEmail(formValues.email)
      .then(() => {
        //console.log("reset email has been sent");
        setLoading(false);
        setEmailSent(true);
      })
      .catch((error) => {
        var errorCode = error.code;
        //console.log(errorCode);
        setErrorMessage(errorDictionary[errorCode]);
        setLoading(false);
      });
  }

  function handleInputChange(e) {
    e.persist();
    setFormValues((currentValues) => ({
      ...currentValues,
      [e.target.name]: e.target.value,
    }));
    setErrorMessage("");
  }

  return (
    <div class="page-header log-in wf-section">
      {loading && <LoadingWall />}
      <h1 class="h1-title">Forgot your password?</h1>
      <p class="subheading-text">We'll send you a password reset email</p>
      <div class="log-in-card">
        <div class="form-block w-form">
          <div class="text-block-5">🩺</div>
          <form
            id="email-form"
            name="email-form"
            data-name="Email Form"
            class="form"
            onSubmit={handleSubmit}
          >
            <input
              value={formValues.email}
              type="email"
              class="text-field-2 w-input"
              maxlength="256"
              name="email"
              data-name="Email"
              placeholder="Email"
              id="email"
              required
              onChange={handleInputChange}
            />
            {!!errorMessage && (
              <div class="w-form-fail">
                <div>Oops! Something went wrong while submitting the form.</div>
              </div>
            )}
            <input
              type="submit"
              value={emailSent ? "Email sent" : "Send email"}
              class={
                emailSent
                  ? "button-success top-displacement w-button"
                  : "button top-displacement w-button"
              }
              disabled={emailSent ? true : false}
            />
          </form>
          {!!errorMessage && (
            <div>
              <br />
              <ErrorMessageText>{errorMessage}</ErrorMessageText>
            </div>
          )}
          <br />
          <RegisterRedirectContainer onClick={() => navigate("/registration")}>
            Need an account?
          </RegisterRedirectContainer>
          <RegisterRedirectContainer onClick={() => navigate("/login")}>
            Back to login
          </RegisterRedirectContainer>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
